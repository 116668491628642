import React, { useRef, useState, useEffect } from 'react';
import { Panel, PanelBody } from '../../components/panel/panel';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { AlertTypes, ApiKey, Status, ApiUrl, Role } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, validatePhoneNumber } from "../../util/Util";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

/// <summary>
/// Author: CK
/// Editted: YJ (Added Registration Function)
/// </summary>
const RegisterMember = props => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, errors, unregister, setValue, getValues } = useForm();
    var _phoneFieldRef = useRef(null);
    var _userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: YJ
    /// </summary>
    const submitForm = async (data, e) => {
        let formattedNumber = _phoneFieldRef.current.getNumber(data.phoneNumber).split(/[- ]+/).join('').replace("+", "");
        let randomPassword = Math.floor(100000 + Math.random() * 900000);

        let params = {
            username: formattedNumber.substr(1),
            userFullName: formattedNumber,
            roleId: Role._MEMBER,
            phoneNumber: formattedNumber,
            regionId: _userData.regionId,
            ownerId: _userData.userId,
            status: Status._ENABLED,
            password: randomPassword,
            pin: randomPassword
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));
        
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY]), 
            `New Member: ${formattedNumber.substr(1)}|Pw: ${randomPassword}`));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        init();
    }, [])

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function init() {
        setValue('phoneNumber', "");
        register({ name: 'phoneNumber' }, {
            required: "PLEASE_ENTER_PHONE",
            validate: { exist: value => (validatePhoneNumber(value, "my", true) && validatePhoneNumber(_phoneFieldRef.current.getNumber(value).split(/[- ]+/).join('').replace("+", ""), "my", true)) || "REQUIRED_VALID_PHONE_NUMBER" }
        });
    }

    return (
        <div id="page-content">
            <div className="row">
                <div className="offset-lg-3 offset-xl-4 col-xl-3 col-lg-6 col-md-12 ui-sortable">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Panel>
                            <PanelBody>
                                <h4 style={{ fontWeight: "unset" }}>{t("REGISTER_A_MEMBER")}</h4>
                                <hr />
                                <div className="form-group m-b-15 ">
                                    <IntlTelInput
                                        fieldName={"phone"}
                                        preferredCountries={['my']}
                                        style={{ display: "none" }}
                                        containerClassName="intl-tel-input"
                                        inputClassName="form-control"
                                        ref={_phoneFieldRef}
                                        useMobileFullscreenDropdown={false}
                                        separateDialCode />

                                    <label>{t("NEW_PHONE_NUMBER")} (01xxxxxx)</label>
                                    <input className="input-search form-control form-control-lg m-b-5"
                                        id="PhoneNumber"
                                        name="phoneNumber"
                                        ref={register}
                                        placeholder={t("ENTER_RELOAD_PHONE_NUMBER")} type="text" />
                                    {errors.phoneNumber && <div className="invalid-feedback">{t(errors.phoneNumber.message)}</div>}
                                </div>
                                <input type="hidden" name="type" id="txt_type" />
                                <div className="pull-right">
                                    <button type="submit" id="btn_search" className="btn btn-primary btn-action">{t("REGISTER_NOW")}</button>
                                </div>
                            </PanelBody>
                        </Panel>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default RegisterMember;